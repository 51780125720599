import React, { useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { TimelineMax, gsap } from "gsap/dist/gsap"
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin"

import NoseLegs from "../images/nose-and-legs.inline.svg"

if (typeof window !== "undefined") {
  gsap.registerPlugin(MorphSVGPlugin)
}

const RefDiv = styled.div`
  position: absolute;
  height: 300vh;
`
const NoseWrapper = styled(NoseLegs)`
  position: fixed;
  bottom: -1.6vh;
  left: 0;
  height: 30vh;
  width: 100vw;
  pointer-events: none;

  #legs,
  #nose {
    visibility: hidden;
  }
`

const Nose = () => {
  const svgWrapper = useRef(null)

  useEffect(() => {
    const [svgElements] = svgWrapper.current.children

    const nose = svgElements.querySelector("#nose")
    const legs = svgElements.querySelector("#path0")
    const all = svgElements.querySelector("#nose-legs")

    function noseF() {
      const tl = new TimelineMax({ repeat: -1 })

      tl.set(nose, { visibility: "visible" })

      tl.to(nose, {
        duration: 2.2,
        yoyo: true,
        repeat: 20,
        scaleY: 1.2,
        scaleX: 1.05,
        transformOrigin: "bottom left",
        ease: "linear",
      })

      return tl
    }

    function legsF() {
      const tl = new TimelineMax({
        defaults: { duration: 0.02, ease: "power4" },
        repeat: -1,
      })

      tl.set(legs, { visibility: "visible" })

      tl.to(legs, { morphSVG: "#path1" })
        .to(legs, { morphSVG: "#path2" })
        .to(legs, { morphSVG: "#path3" })
        .to(legs, { morphSVG: "#path4" })
        .to(legs, { morphSVG: "#path5" })
        .to(legs, { morphSVG: "#path6" })
        .to(legs, { morphSVG: "#path7" })
        .to(legs, { morphSVG: "#path8" })
        .to(legs, { morphSVG: "#path9" })
        .to(legs, { morphSVG: "#path10" })
        .to(legs, { morphSVG: "#path11" })
        .to(legs, { morphSVG: "#path12" })
        .to(legs, { morphSVG: "#path13" })
        .to(legs, { morphSVG: "#path14" })
        .to(legs, { morphSVG: "#path15" })
        .to(legs, { morphSVG: "#path16" })
        .to(legs, { morphSVG: "#path17" })
        .to(legs, { morphSVG: "#path18" })
        .to(legs, { morphSVG: "#path19" })
        .to(legs, { morphSVG: "#path20" })
        .to(legs, { morphSVG: "#path21" })
        .to(legs, { morphSVG: "#path22" })
        .to(legs, { morphSVG: "#path23" })
        .to(legs, { morphSVG: "#path24" })
        .to(legs, { morphSVG: legs })

      return tl
    }

    function horizontalMotionF() {
      const tl = new TimelineMax({ repeat: 0 })

      tl.set(all, { visibility: "visible", x: -50 })

      //tl.to(all, { duration: window_width / 50, x: width, ease: "linear" })
      tl.to(all, { duration: 1280 / 50, x: 1280, ease: "linear" })

      tl.set(all, { display: "none" })

      return tl
    }

    const master = new TimelineMax({ delay: 4 })
    master
      .add(noseF(), 0)
      .add(legsF({ duration: 3 }), 0)
      .add(horizontalMotionF(), 0)
    console.log("working")
  }, [])

  return (
    <RefDiv ref={svgWrapper}>
      <NoseWrapper />
    </RefDiv>
  )
}

export default Nose
