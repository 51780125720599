import React, { useState } from "react"
import styled from "@emotion/styled"
import useSound from "use-sound"
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import Background from "../components/background"
import Nose from "../components/nose"
import { color } from "../utils"

import pop from "../sounds/pop.mp3"

const Container = styled.article`
  margin: 0;
  min-height: 100vh;
  padding: 2rem;
  color: ${color.textBackgroundBlack};
  font-size: 1.1rem;
  line-height: 2rem;
  background-color: ${color.backgroundTransparencyBlack};
  opacity: 0;

  animation: fade 1.2s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 3% 10%;
    padding: 3rem 6rem;
    font-size: 1.2rem;
    line-height: 2.5rem;
    min-height: 70vh;
  }
`
const Hello = styled.h1`
  &.hello {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: #ffe100;
    font-weight: 900;
  }

  @media screen and (min-width: 768px) {
    &.hello {
      font-size: 2.5rem;
      margin: 2rem;
    }
  }
`
const TextMain = styled.p`
  text-justify: auto;
  margin-bottom: 1.7rem;

  span {
    font-weight: 500;
  }

  &.email {
    font-size: 0.9rem;
    text-align: center;
    background-color: #0000006b;
  }
  &.ps {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 768px) {
    &.email {
      font-size: 1.3rem;
    }
    &.ps {
      font-size: 1rem;
    }
  }
`
const CheckboxWrapper = styled.button`
  background: none;
  padding: 0 6px 0 0;
  border: none;
  color: #56fb03;
  vertical-align: middle;

  :focus {
    outline: none;
  }
`
const Span = styled.span`
  font-size: 1.4rem;
  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
  }
`
const Isabel = styled(Span)`
  color: ${color.textColorBright};
`
const Logic = styled(Span)`
  color: #ff00a3;
`
const Design = styled(Span)`
  color: #5cffff;

  &.asterisk {
    color: #f7e82b;
  }
`
const Next = styled(Span)`
  color: #e070ec;
`

const About = () => {
  const [check, setCheck] = useState(false)

  const [playActive] = useSound(pop, { volume: 0.25 })

  return (
    <Layout>
      <SEO title="About" />
      <Background opacity="low" />
      <Container>
        <Hello className="hello">Yellow there!</Hello>
        <TextMain>
          My name is <Isabel>Isabel Moreira</Isabel> and I'm a self-taught
          freelance Web Developer. I've been programming for a few years, and
          really enjoy intertwining <Logic>code</Logic> with{" "}
          <Design>design</Design>.
        </TextMain>
        <TextMain style={{ marginBottom: 0 }}>
          I'm based in Porto, Portugal (an awesome city!), and I love
        </TextMain>
        <TextMain style={{ margin: "0px 2rem" }}>
          <CheckboxWrapper
            aria-label="list item checkbox"
            onClick={() => {
              setCheck(!check)
              playActive()
            }}
          >
            {check ? <FaRegCheckSquare /> : <FaRegSquare />}
          </CheckboxWrapper>
          <span
            style={
              check
                ? {
                    textDecoration: "line-through",
                    color: "#56fb03",
                  }
                : null
            }
          >
            checking to-do lists
          </span>
        </TextMain>
        <TextMain>
          and that feeling you have when you (finally) fix a programming bug!
        </TextMain>
        <TextMain>
          You can regularly find me reading, baking, playing boardgames or
          videogames (I guess I'm addicted to{" "}
          <span style={{ color: "blue" }}>t</span>
          <span style={{ color: "#12ff12" }}>e</span>
          <span style={{ color: "orange" }}>t</span>
          <span style={{ color: "yellow" }}>r</span>
          <span style={{ color: "red" }}>i</span>
          <span style={{ color: "#6ed8fb" }}>s</span>{" "}
          <span style={{ color: "#12ff12" }}>9</span>
          <span style={{ color: "orange" }}>9</span>...), "studying" japanese or
          maybe even practicing some bass guitar (well... trying to!).
        </TextMain>
        <TextMain>
          I love challenges and I'm always eager to work on exciting and
          inspiring projects, with intelligent and fun people!{" "}
          <Next>
            Maybe you're next?...{" "}
            <span role="img" aria-label="smiley face">
              {" "}
              😊{" "}
            </span>
          </Next>
        </TextMain>
      </Container>
      <div style={{ position: "fixed", left: "-200px", zIndex: 5 }}>
        <Nose />
      </div>
    </Layout>
  )
}

export default About
